<template>
  <ValidationObserver v-slot="{ invalid }">
    <popup-base
      :title="mode == 0 ? 'Cadastrar perfil' : 'Editar perfil'"
      size="lg"
      @close="showModal = $event"
      :showModal="showModal"
    >
      <template slot="content">
        <vs-row>
          <ValidationProvider
            name="nome do perfil"
            rules="required"
            v-slot="{ errors }"
            tag="vs-col"
          >
            <vs-input
              label="Nome"
              size="large"
              v-model="item.Name"
              :danger="!!errors[0]"
              :danger-text="errors[0]"
            />
          </ValidationProvider>
        </vs-row>

        <vs-row>
          <vs-col vs-lg="12" class="inline-flex">
            <vs-switch v-model="item.IsSettable"></vs-switch>
            <label class="ml-2"> Habilitar perfil para seleção</label>
          </vs-col>
        </vs-row>
        <vs-row
          v-for="(menu, indexMenu) in menuFuncionalities"
          :key="indexMenu"
        >
          <vs-col vs-sm="12">
            <p>{{ menu.Name }}</p>
          </vs-col>
          <vs-col
            v-for="(funcionality, indexFuncionality) in menu.MenuFuncionalities"
            :key="indexFuncionality"
          >
            <vs-checkbox
              v-model="item.MenuFuncionalityId"
              :vs-value="funcionality.Id"
            >
              {{ funcionality.Name }}
            </vs-checkbox>
          </vs-col>
        </vs-row>

        <vs-row class="mt-4">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{ required: requiredMenu }"
            name="home menu do perfil"
            tag="vs-col"
            class="container-element"
          >
            <p class="label-content label">Home menu</p>

            <v-select
              label="Name"
              v-model="item.MenuHomeId"
              :show-no-options="false"
              :show-no-results="false"
              :options="menuSelectedList"
              :reduce="menu => menu.Id"
              :danger="!!errors[0]"
              :danger-text="errors[0]"
            >
              <template #no-options>
                Atribua alguma funcionalidade ao perfil para selecionar o home
              </template>
            </v-select>

            <span class="text-error" v-show="errors[0]">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </vs-row>
      </template>

      <template slot="footer">
        <vs-row class="py-0">
          <vs-col vs-type="flex" vs-justify="end">
            <vs-button
              class="mr-3"
              color="primary"
              type="border"
              @click="showModal = false"
            >
              Cancelar
            </vs-button>

            <vs-button
              color="primary"
              @click="salvarPerfil()"
              :disabled="invalid"
            >
              Salvar
            </vs-button>
          </vs-col>
        </vs-row>
      </template>
    </popup-base>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    showPopup: { type: Boolean, default: false },
    mode: { type: Number, default: 0 },
    value: { type: Object },
    userTypeId: { type: Number },
    allowedReset: { type: Boolean, default: false }
  },
  data() {
    return {
      item: { IsSettable: true, MenuFuncionalityId: [] }
    };
  },
  computed: {
    ...mapGetters("perfil-module", ["perfilList", "menuFuncionalities"]),

    showModal: {
      get() {
        return this.showPopup;
      },

      set(value) {
        this.$emit("setShowPopup", value);
      }
    },

    menuSelectedList() {
      let menus = [];
      (this.menuFuncionalities||[]).forEach(menu => {
        let menuFuncionalities = menu.MenuFuncionalities.filter(funcionality =>
          this.item.MenuFuncionalityId.includes(funcionality.Id)
        );

        if (menuFuncionalities && menuFuncionalities.length)
          menus.push({ Name: menu.Name, Id: menu.Id });
      });

      return menus;
    },

    requiredMenu() {
      return !!(this.menuSelectedList && this.menuSelectedList.length);
    }
  },
  methods: {
    ...mapActions("perfil-module", [
      "getMenuFuncionalities",
      "insertOrUpdateProfile",
      "updateMenuFuncionalityInProfile"
    ]),
    ...mapMutations("perfil-module", ["addProfile", "updateProfile"]),

    async salvarPerfil() {
      await this.$onpoint.loading(async () => {
        return await this.insertOrUpdateProfile({
          Name: this.item.Name,
          IsSettable: this.item.IsSettable,
          UniqueId: this.item.UniqueId,
          MenuHomeId: this.item.MenuHomeId,
          UserInfoTypeId: this.userTypeId || 0
        }).then(data => {
          if (this.mode == 0) this.item.UniqueId = data;

          this.salvarFuncionalidades();
        });
      });
    },

    async salvarFuncionalidades() {
      await this.$onpoint.loading(async () => {
        return await this.updateMenuFuncionalityInProfile({
          ProfileUniqueId: this.item.UniqueId,
          MenuFuncionalityId: this.item.MenuFuncionalityId
        }).then(() => {
          this.showModal = false;

          let perfil = {
            Name: this.item.Name,
            UniqueId: this.item.UniqueId,
            IsSettable: this.item.IsSettable,
            MenuHomeId: this.item.MenuHomeId,
            Menus: []
          };

          this.menuFuncionalities.forEach(menu => {
            let menuFuncionalities = menu.MenuFuncionalities.filter(
              funcionality =>
                this.item.MenuFuncionalityId.includes(funcionality.Id)
            );

            if (menuFuncionalities && menuFuncionalities.length)
              perfil.Menus.push({
                Name: menu.Name,
                Id: menu.Id,
                MenuFuncionalities: menuFuncionalities
              });
          });

          this.removeUsers;

          if (this.mode == 0) this.addProfile(perfil);
          else {
            this.updateProfile(perfil);
          }
        });
      });
    },

    async loadGetMenuFuncionalities() {
      await this.$onpoint.loading(async () => {
        return await this.getMenuFuncionalities(
          !!this.userTypeId ? this.userTypeId : null
        );
      });
    }
  },
  watch: {
    showModal(val) {
      if (!val) {
        this.item = { IsSettable: true, MenuFuncionalityId: [] };
      } else {
        if (this.mode == 1) {
          this.item = {
            Name: this.value.Name,
            IsSettable: this.value.IsSettable,
            UniqueId: this.value.UniqueId,
            MenuHomeId: this.value.MenuHomeId,
            MenuFuncionalityId: []
          };

          this.value.Menus.forEach(element => {
            element.MenuFuncionalities.forEach(menuFunc =>
              this.item.MenuFuncionalityId.push(menuFunc.Id)
            );
          });
        }
      }
    }
  },
  async created() {
    if (
      !this.menuFuncionalities ||
      !this.menuFuncionalities.length ||
      this.allowedReset
    )
      await this.loadGetMenuFuncionalities();
  }
};
</script>

<style lang="scss"></style>
